<template>
    <div class="container-fluid mt-lg-3">
        <div class="card">
            <div class="card-body">
                <div class="surah-list">
                    <div class="row">
                        <div class="col-md-4">
                            <div v-for="(surah,index) in dataList" :key="surah.number">
                                <router-link :to="{name:'surah',params:{no:surah.number}}" class="link-surah-list" v-if="index >= 0 && index <= dataLimit">
                                    <div class="border margin-top-surah">
                                        <div class="no-list-wrapper">
                                            <p class="no-surah-list">{{surah.number}}</p>
                                        </div>

                                        <div class="surah-list-content">
                                            <div class="d-inline-flex">
                                                <div class="surah-list-wrap">
                                                    <p class="surah-list-title">{{surah.name.transliteration.id}} ({{surah.numberOfVerses}})</p>
                                                    <p class="surah-list-mean">{{surah.name.translation.id}}</p>
                                                </div>
                                            </div>
                                            <div class="d-inline-flex float-right">
                                                <p class="surah-list-arab"> {{surah.name.short}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div v-for="(surah,index) in dataList" :key="surah.number">
                                <router-link :to="{name:'surah',params:{no:surah.number}}" class="link-surah-list" v-if="index >= dataLimit+1 && index <= dataLimit+dataLimit+1">
                                    <div class="border margin-top-surah">
                                        <div class="no-list-wrapper">
                                            <p class="no-surah-list">{{surah.number}}</p>
                                        </div>

                                        <div class="surah-list-content">
                                            <div class="d-inline-flex">
                                                <div class="surah-list-wrap">
                                                    <p class="surah-list-title">{{surah.name.transliteration.id}} ({{surah.numberOfVerses}})</p>
                                                    <p class="surah-list-mean">{{surah.name.translation.id}}</p>
                                                </div>
                                            </div>
                                            <div class="d-inline-flex float-right">
                                                <p class="surah-list-arab"> {{surah.name.short}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div v-for="(surah,index) in dataList" :key="surah.number">
                                <router-link :to="{name:'surah',params:{no:surah.number}}" class="link-surah-list" v-if="index >= dataLimit*2+2 && index <= dataLimit*3+1">
                                    <div class="border margin-top-surah">
                                        <div class="no-list-wrapper">
                                            <p class="no-surah-list">{{surah.number}}</p>
                                        </div>

                                        <div class="surah-list-content">
                                            <div class="d-inline-flex">
                                                <div class="surah-list-wrap">
                                                    <p class="surah-list-title">{{surah.name.transliteration.id}} ({{surah.numberOfVerses}})</p>
                                                    <p class="surah-list-mean">{{surah.name.translation.id}}</p>
                                                </div>
                                            </div>
                                            <div class="d-inline-flex float-right">
                                                <p class="surah-list-arab"> {{surah.name.short}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>
    </div>
</template>

<script>
export default {
    data(){
        return{
            dataList:{},
            dataLimit:0,
        }
    },
    mounted(){
        this.getDataSurah()
    },
    methods:{
        getDataSurah(){
            this.axios.get(this.$url+'surah')
            .then(res => {
                this.dataList = res.data.data
                this.dataLimit =  this.dataList.length / 3;
                console.log(res.data.data);
            }).catch(err => {
                console.log(err);
            })
        }
    }
}
</script>

<style>
@import '../assets/css/style.css';

</style>